<template>
  <el-drawer
    :title="drawerTitle"
    custom-class="g-drawer"
    :wrapper-closable="false"
    :visible="drawerVisible"
    size="600px"
    @open="open"
    @close="close"
  >
    <div class="g-drawer-c">
      <el-form ref="form" :model="drawerForm" :rules="drawerRules" :disabled="formDisabled" label-position="right" label-width="140px">
        <el-form-item label="关联用户" prop="ext1">
          <div style="line-height: 15px;width: 70%;">
            <UserInfoCard :need-change-user-info="false" :need-match="false" :user-info="companyInfo" :show-bg="true" />
          </div>
        </el-form-item>
        <el-row :gutter="8">
          <el-col :span="24">
            <el-form-item label="车牌号" prop="truckNo">
              <el-input v-model="drawerForm.truckNo" :disabled="drawerType!=='add'" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="8">
          <el-col :span="12">
            <el-form-item label="车辆类型" prop="loadType">
              <el-select v-model="drawerForm.extMap.loadType" clearable placeholder="载重">
                <el-option v-for="item in $store.state.dictionary.loadTypeArrayMap" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- <el-form-item label="" prop="truckType"> -->
            <el-select v-model="drawerForm.truckType" clearable placeholder="载运形式">
              <el-option v-for="item in dictTruckTypeMap" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <!-- </el-form-item> -->
          </el-col>
          <el-col :span="6">
            <!-- <el-form-item label="" prop="truckType"> -->
            <el-select v-model="drawerForm.extMap.tractionHanger" clearable placeholder="牵引挂" @change="changeTractionHanger">
              <el-option v-for="item in dictTruckHangMap" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <!-- </el-form-item> -->
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="12">
            <el-form-item label="牵引重量" prop="truckTotalTon">
              <el-input v-model="drawerForm.truckTotalTon" placeholder="请输入" clearable maxlength="16" @input="validateNumber('truckTotalTon', 16)">
                <template v-slot:append>
                  <span class="unit">kg</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="环保级别" prop="truckCleanLevel">
              <el-select v-model="drawerForm.truckCleanLevel" clearable placeholder="请选择">
                <el-option v-for="item in dictTruckCleanLevelMap" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="核载" prop="truckLoadTon">
              <el-input v-model="drawerForm.truckLoadTon" placeholder="请输入" clearable maxlength="16" @input="validateNumber('truckLoadTon', 16)">
                <template v-slot:append>
                  <span class="unit">吨</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
        <!-- <div class="division-title">行驶证</div> -->
        <el-form-item label="行驶证有效期止" prop="truckLicDestroyTime">
          <el-date-picker
            v-model="drawerForm.extMap.truckLicDestroyTime"
            value-format="timestamp"
            type="date"
            placeholder="请选择"
            :picker-options="pickerOptions"
          />
        </el-form-item>
        <el-form-item label="行驶证照片" prop="truckLicPic">
          <ImageUpload
            :model-value.sync="drawerForm.extMap.truckLicPic"
            placeholder="行驶证照片"
            :mode="formDisabled ? 'preview' : 'edit'"
          />
        </el-form-item>
        <!-- <el-form-item label="" prop="truckPic">
          {{ drawerForm.truckPic }}/
          <d5c-upload :key-list-string.sync="drawerForm.truckPic" placeholder="车辆图片" :limit="1" :img-type="1" :mode="formDisabled ?'preview':'edit'" />
        </el-form-item> -->
        <!-- <div class="division-title">道路运输证</div> -->
        <el-form-item
          label="道路运输证有效期止"
          prop="roadLicExpireTime"
        >
          <el-date-picker
            v-model="drawerForm.extMap.roadLicExpireTime"
            value-format="timestamp"
            type="date"
            placeholder="请选择"
            :picker-options="pickerOptions"
          />
        </el-form-item>
        <el-form-item label="道路运输证照片" prop="roadLicPic">
          <ImageUpload
            :model-value.sync="drawerForm.extMap.roadLicPic"
            placeholder="道路运输证照片"
            :mode="formDisabled ? 'preview' : 'edit'"
          />
        </el-form-item>
        <!-- <div class="division-title">保险</div> -->
        <el-form-item
          label="保险有效期止"
          prop="insuranceExpireTime"
        >
          <el-date-picker
            v-model="drawerForm.extMap.insuranceExpireTime"
            value-format="timestamp"
            type="date"
            placeholder="请选择"
            :picker-options="pickerOptions"
          />
        </el-form-item>
        <el-form-item label="保险照片" prop="insurancePic">
          <ImageUpload
            :model-value.sync="drawerForm.extMap.insurancePic"
            placeholder="保险照片"
            :mode="formDisabled ? 'preview' : 'edit'"
          />
        </el-form-item>
        <template v-if="+drawerForm.extMap.tractionHanger">
          <div class="division-title">罐体信息</div>
          <el-row :gutter="0">
            <el-col :span="24">
              <el-form-item label="允装介质" prop="tankFullType">
                <el-input
                  v-model="drawerForm.extMap.tankFullType"
                  placeholder="请输入"
                  type="textarea"
                  :rows="2"
                  clearable
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col :span="12">
              <el-form-item label="类别" prop="tankType">
                <el-select v-model="drawerForm.extMap.tankType" class="g-inp" clearable placeholder="请选择">
                  <el-option v-for="item in dictTruckTankTypeMap" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="材质" prop="tankMaterial">
                <el-select v-model="drawerForm.extMap.tankMaterial" class="g-inp" clearable placeholder="请选择">
                  <el-option v-for="item in dictTruckTankMaterialMap" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="容积" prop="tankVolume">
                <el-input v-model="drawerForm.extMap.tankVolume" placeholder="请输入" clearable @input="validateNumberMultilevel('extMap','tankVolume',6,3)">
                  <template v-slot:append>
                    <span class="unit">m³</span>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="罐检证有效期止" prop="tankCheckExpireTime">
            <el-date-picker
              v-model="drawerForm.extMap.tankCheckExpireTime"
              value-format="timestamp"
              type="date"
              placeholder="请选择"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <el-form-item label="罐检证照片" prop="tankCheckPic">
            <ImageUpload
              :model-value.sync="drawerForm.extMap.tankCheckPic"
              placeholder="罐检证照片"
              :mode="formDisabled ? 'preview' : 'edit'"
            />
          </el-form-item>
        </template>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="drawerForm.remark" type="textarea" placeholder="请输入" clearable />
        </el-form-item>
      </el-form>
    </div>
    <div class="g-btn-c">
      <el-button @click="close">取消</el-button>
      <el-button v-if="drawerTypeDesc==='add'||drawerTypeDesc==='edit'" type="primary" @click="submit">提交</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="danger" @click="deleteClick">删除</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="primary" @click="changeEdit">编辑</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateCarNO } from '@/utils/validateRule';
import { addTruck, editTruck, getDetailTruck, deleteTruck } from '@/api/truck';
export default {
  props: {
    // 详情类型 add 新增 edit编辑 detail详情
    drawerType: {
      type: String,
      default() {
        return 'add';
      },
    },
    drawerVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    drawerId: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    companyInfo: {
      type: Object,
      default() {
        return {
          // companyId: null,
        };
      },
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      drawerTypeDesc: '',
      drawerTitleObj: {
        'add': '新增车辆',
        'edit': '编辑车辆',
        'detail': '查看车辆',
      },
      // dictTruckColourMap: [], // 车牌颜色
      dictTruckTypeMap: [], // 车辆类型（载运类型）
      dictTruckHangMap: [], // 牵引挂类型
      dictTruckCleanLevelMap: [], // 环保级别
      dictTruckPowerTypeMap: [], // 燃油方式
      dictTruckTankTypeMap: [], // 罐体类别
      dictTruckTankMaterialMap: [], // 罐体材质

      drawerForm: {
        truckNo: '',
        extMap: {
          loadType: '', // 1车辆类型(载运类型 载重）
          tractionHanger: '', // 3牵引挂
          truckLicDestroyTime: '', // 7行驶证有效期止
          truckLicPic: '', // 8行驶证照片
          roadLicExpireTime: '', // 9道路运输证有效期止
          roadLicPic: '', // 10道路运输证照片
          insuranceExpireTime: '', // 11保险有效期止
          insurancePic: '', // 12保险照片
          tankFullType: '', // 13 罐体允装介质
          tankType: '', // 14 罐体 类别
          tankMaterial: '', // 15 罐体 材质
          tankVolume: '', // 16 罐体 容积
          tankCheckExpireTime: '', // 17 罐检证有效期止
          tankCheckPic: '', // 18 罐检证照片
        },
        // truckType: '', // 2载运形式
        // truckTotalTon: '', // 4牵引重量
        // truckCleanLevel: '', // 5环保级别
        // truckLoadTon: '', // 6核载
      },
      drawerRules: {
        truckNo: [
          { required: true, message: '请输入车牌号', trigger: 'change' },
          { validator: validateCarNO, trigger: 'change' },
        ],
        truckLoadTon: [
          { required: true, message: '请输入核载', trigger: 'change' },
          // { validator: validateCarNO, trigger: 'change' },
        ],
      },
    };
  },

  computed: {
    formDisabled() {
      return this.drawerTypeDesc === 'detail';
    },
    drawerTitle() {
      return this.drawerTitleObj[this.drawerTypeDesc];
    },
    ...mapGetters({
      dics: 'dictionary/dics',
    }),
  },

  watch: {
    // 监听路由变化，如果发生变化则关闭drawer
    '$route': function(to, from) {
      this.drawerVisible = false;
    },
  },
  methods: {
    open() {
      this.getDictionaryList();
      this.drawerTypeDesc = this.drawerType;
      if (this.drawerTypeDesc === 'detail' || this.drawerTypeDesc === 'edit') {
        this.getDetail();
      }
    },
    close() {
      this.drawerForm = {
        extMap: {
          loadType: '', // 1车辆类型(载运类型 载重）
          tractionHanger: '', // 3牵引挂
          truckLicDestroyTime: '', // 7行驶证有效期止
          truckLicPic: '', // 8行驶证照片
          roadLicExpireTime: '', // 9道路运输证有效期止
          roadLicPic: '', // 10道路运输证照片
          insuranceExpireTime: '', // 11保险有效期止
          insurancePic: '', // 12保险照片
          tankFullType: '', // 13 罐体允装介质
          tankType: '', // 14 罐体 类别
          tankMaterial: '', // 15 罐体 材质
          tankVolume: '', // 16 罐体 容积
          tankCheckExpireTime: '', // 17 罐检证有效期止
          tankCheckPic: '', // 18 罐检证照片
        },
        // truckType: '', // 2载运形式
        // truckTotalTon: '', // 4牵引重量
        // truckCleanLevel: '', // 5环保级别
        // truckLoadTon: '', // 6核载
      };
      this.$refs.form.resetFields();
      this.$emit('update:drawerVisible', false);
    },
    // 获取数据字典数组
    async getDictionaryList() {
      const params = ['dict_truck_type', 'dict_truck_hang', 'dict_truck_clean_level', 'dict_truck_power_type', 'dict_tank_type', 'dict_tank_material'];
      const data = await this.$store.dispatch('dictionary/get', params);
      if (data) {
        // this.dictTruckColourMap = data['dict_truck_colour']?.A || [];
        this.dictTruckTypeMap = data['dict_truck_type']?.A || [];
        this.dictTruckHangMap = data['dict_truck_hang']?.A || [];
        this.dictTruckCleanLevelMap = data['dict_truck_clean_level']?.A || [];
        this.dictTruckPowerTypeMap = data['dict_truck_power_type']?.A || [];
        this.dictTruckTankTypeMap = data['dict_tank_type']?.A || [];
        this.dictTruckTankMaterialMap = data['dict_tank_material']?.A || [];
      }
    },
    getDetail() {
      getDetailTruck({ truckId: this.drawerId }).then(res => {
        this.drawerForm = res || {};
      });
    },
    deleteClick() {
      const params = {
        id: this.drawerId,
      };
      deleteTruck(params).then(res => {
        this.$baseMessage('删除成功!', 'success');
        this.$emit('drawerSubmit');
        this.close();
      });
    },
    changeEdit() {
      this.drawerTypeDesc = 'edit';
    },
    changeTractionHanger(val) {
      console.log(val, 'val');
    },
    submit() {
      // type 获取type 分别对应api提交
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          try {
            const drawerForm = JSON.parse(JSON.stringify(this.drawerForm));
            const apiMap = {
              'add': addTruck,
              'edit': editTruck,
            };
            const res = await apiMap[this.drawerTypeDesc](drawerForm);
            if (res) {
              this.$baseMessage(`${this.drawerTitle}成功!`, 'success');
              this.$emit('drawerSubmit');
              this.close();
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    validateNumber(val, limitNum, decimals) {
      // 正则限制输入的金额不能为中文、特殊字符、两位小数
      const reg = /^[0-9]+.?[0-9]*/;
      // 如果不符合正则，提示
      if (!reg.test(+this.drawerForm[val])) {
        this.drawerForm[val] = '';
      }
      // 保留两位小数
      const price = this.drawerForm[val].toString();
      const pArr = price.split('.');
      pArr[0] = pArr[0].substr(0, limitNum);
      if (pArr.length > 1 && decimals) {
        this.drawerForm[val] = pArr[0] + '.' + pArr[1].substr(0, decimals);
      } else {
        this.drawerForm[val] = pArr[0];
      }
    },
    validateNumberMultilevel(pval, cval, limitNum, decimals) {
      // 正则限制输入的金额不能为中文、特殊字符、两位小数
      const reg = /^[0-9]+.?[0-9]*/;
      // 如果不符合正则，提示
      if (!reg.test(+this.drawerForm[pval][cval])) {
        this.drawerForm[pval][cval] = '';
      }
      // 保留两位小数
      const price = this.drawerForm[pval][cval].toString();
      const pArr = price.split('.');
      pArr[0] = pArr[0].substr(0, limitNum);
      if (pArr.length > 1 && decimals) {
        this.drawerForm[pval][cval] = pArr[0] + '.' + pArr[1].substr(0, decimals);
      } else {
        this.drawerForm[pval][cval] = pArr[0];
      }
    },
  },
};

</script>
<style lang='scss' scoped>
</style>

<template>
  <div class="basic-data-wrap flex">
    <leftPanel ref="leftPanel" :class="['left-wrap',isFold?'left-half':'left-all']" :is-fold.sync="isFold" :company-type="2" @changeCompany="changeCompany" @leftCompanyData="leftCompanyData" />
    <div :class="['right-wrap g-search-form-table',isFold?'right-half':'right-all']">
      <search-form id="search-form" :model="form" :count="2" label-width="auto" :collapse="false" @search="searchHandle">
        <search-item label="" prop="truckNo">
          <el-input v-model.trim="form.truckNo" clearable placeholder="车牌号" />
        </search-item>
        <search-item label="" prop="ext1">
          <el-select v-model="form.ext1" class="g-inp" clearable placeholder="车载设备">
            <!-- <el-option v-for="item in allDispatch" :key="item.userId" :label="item.userName" :value="item.userId" /> -->
          </el-select>
        </search-item>
      </search-form>
      <Table
        :loading="loading"
        :table-height="tableHeight"
        :show-selection-change="true"
        :table-cols="tableCols"
        :table-data="tableData"
        :table-btns="tableBtns"
        :pagination="pagination"
        :multiple-selection.sync="multipleSelection"
        @selectTime="selectTime"
        @sizeChange="sizeChange"
        @pageChange="pageChange"
      >
        <template v-slot="{ col, row }">
          <div v-if="col.prop === 'truckType'">
            {{ dictTruckTypeData.O[row.truckType]||'-' }}
          </div>
          <div v-if="col.prop === 'truckCleanLevel'">
            {{ dictTruckCleanLevelData.O[row.truckCleanLevel]||'-' }}
          </div>
        </template>
      </Table>
      <Drawer :drawer-visible.sync="drawerVisible" :drawer-type="drawerType" :drawer-id="id" :company-info="companyInfo" @drawerSubmit="drawerSubmit" />
    </div>
  </div>
</template>

<script>
import { getTruck, batchDeleteTruck } from '@/api/truck';
import { exportByJson, parseTime } from '@/utils';
import leftPanel from '../components/leftPanel';
import Drawer from './drawer';
export default {
  name: 'BasicdataTruck',
  components: { leftPanel, Drawer },
  data() {
    return {
      isFold: false,
      tableHeight: 'auto',
      companyInfo: {}, // 当前所选的左侧公司信息 相关接口需要此参数
      loading: false,
      dictTruckTypeData: {
        A: [],
        O: {},
      }, // 车辆类型
      dictTruckCleanLevelData: {
        A: [],
        O: {},
      }, // 环保级别
      form: { },
      tableBtns: [
        {
          label: '新增车辆',
          icon: 'el-icon-circle-plus-outline',
          evt: () => {
            this.openDialog('add');
          },
        },
        {
          label: '删除',
          type: 'danger',
          evt: () => {
            this.delete();
          },
        },
        {
          label: '导入',
          type: 'default',
          evt: () => {
            this.onExport();
          },
        },
        {
          label: '导出',
          type: 'default',
          evt: () => {
            this.onExport();
          },
        },
      ],
      tableCols: [
        { label: '车牌号', prop: 'truckNo', fixed: 'left', type: 'jump', evt: (data) => { this.openDialog('detail', data); } },
        { label: '车载设备', prop: 'mobileUnit', type: 'transition', rulesObj: { 0: '未对接', 1: '已对接' }},
        { label: '车辆类型', prop: 'truckType', type: 'customize' },
        { label: '环保级别', prop: 'truckCleanLevel', type: 'customize' },
        { label: '牵引重量(kg)', prop: 'truckTotalTon', width: 125 },
        { label: '创建人', prop: 'createUsername' },
        { label: '创建时间 ', prop: 'createTime', type: 'filterTime', width: '160' },
        { label: '更新人', prop: 'updateUsername' },
        { label: '更新时间', prop: 'updateTime', sortable: 'custom', headTimeSelect: true, width: 170, type: 'filterTime' },
        { label: '操作', type: 'operation', fixed: 'right',
          btns: [
            {
              label: '编辑',
              evt: (data) => {
                this.openDialog('edit', data);
              },
            },
          ],
        },
      ],
      tableData: [],
      multipleSelection: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      id: null,
      drawerType: 'add',
      drawerVisible: false,

    };
  },
  created() {
    this.getDictionaryList();
  },
  mounted() {
    this.$nextTick(() => {
      this.handleResize();
    });
    window.addEventListener('resize', this.handleResize, true);
  },
  deactivated() {
    window.removeEventListener('resize', this.handleResize, true);
  },
  methods: {
    handleResize() {
      const screenHeight = document.documentElement.clientHeight;
      this.formHeight = document.getElementById('search-form').clientHeight;
      const otherHeight = this.formHeight + 14 + 82 + 20 + 56 + 52 + 20;
      this.tableHeight = screenHeight - otherHeight + 'px';
    },
    // 获取数据字典数组
    async getDictionaryList() {
      const params = ['dict_truck_type', 'dict_truck_clean_level'];
      const data = await this.$store.dispatch('dictionary/get', params);
      if (data) {
        this.dictTruckTypeData = data['dict_truck_type'];
        this.dictTruckCleanLevelData = data['dict_truck_clean_level'];
      }
    },
    // 获取列表数据
    getList() {
      this.loading = true;
      const queryForm = JSON.parse(JSON.stringify(this.form));
      // if (this.form.state.length) {
      //   queryForm.state = this.form.state.join(',');
      // } else {
      //   queryForm.state = '';
      // }
      const params = {
        // companyId: '', // 企业Id
        createUser: this.$store.state.common.AUV_USER_ID,
        ...queryForm,
        current: this.pagination.page,
        size: this.pagination.size,
      };
      getTruck(params).then(res => {
        this.tableData = res?.records || [];
        this.pagination = {
          page: res?.current || 1,
          size: res?.size || 10,
          total: res?.total || 0,
        };
      }).finally(() => {
        this.loading = false;
      });
    },
    // 查询按钮操作
    searchHandle() {
      // 防止点击重置置空以下参数影响筛选
      this.form.companyId = this.companyInfo.companyId; // 企业id
      this.getList();
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    // 删除所选
    delete() {
      if (!this.multipleSelection.length) {
        this.$baseMessage('您还未勾选,请先勾选!', 'error');
        return;
      }
      const params = {
        ids: this.multipleSelection.map(d => d.id) || null,
      };
      batchDeleteTruck(params).then(res => {
        this.$baseMessage('删除成功!', 'success');
        this.getList();
      });
    },
    // 前端导出excel
    onExport() {
      const queryForm = JSON.parse(JSON.stringify(this.form));
      // if (this.form.state.length) {
      //   queryForm.state = this.form.state.join(',');
      // } else {
      //   queryForm.state = '';
      // }
      const params = {
        createUser: this.$store.state.common.AUV_USER_ID,
        ...queryForm,
        current: 1,
        size: 999,
      };
      getTruck(params).then(res => {
        if (!res) {
          this.$baseMessage('暂无数据可导出!', 'error');
          return;
        }
        const header = ['车牌号', '车载设备', '车辆类型', '环保级别', '牵引重量(kg)', '创建人', '创建时间', '更新人', '更新时间'];
        const exportData = res.records.map((item) => {
          return {
            truckNo: item.truckNo,
            mobileUnit: item.mobileUnit || '-',
            truckType: this.dictTruckTypeData.O[item.truckType] || '-',
            truckCleanLevel: this.dictTruckCleanLevelData.O[item.truckCleanLevel] || '-',
            truckLoadTon: item.truckLoadTon || '-',
            createUsername: item.createUsername,
            createTime: parseTime(item.updateTime),
            updateUsername: item.updateUsername,
            updateTime: parseTime(item.updateTime),
          };
        });
        exportByJson(exportData, header, '车辆列表.xlsx');
      }).catch((err) => {
        console.log(err);
        this.$baseMessage('导出失败', 'error');
      });
    },
    selectTime(time) {
      this.form.updateTimeBegin = time ? time[0] : '';
      this.form.updateTimeEnd = time ? time[1] : '';
      this.getList();
    },
    // 打开侧面抽屉 进行新增/编辑/查看操作
    openDialog(type, data) {
      this.id = data?.id || null;
      this.drawerType = type;
      this.drawerVisible = true;
    },
    // 抽屉提交事件
    drawerSubmit() {
      this.getList();
    },
    // left返回的id
    leftCompanyData(data) {
      this.companyInfo = data;
      this.form.companyId = data.companyId;
      this.getList();
    },
    // 左侧切换公司 重新发起请求
    changeCompany(data) {
      this.companyInfo = data;
      this.form.companyId = data.companyId;
      this.getList();
    },
  },
};

</script>
<style lang='scss' scoped>
@import "@/assets/scss/basicData.scss";
</style>
